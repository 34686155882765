import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

function Demo() {
  const [useGrey, setUseGrey] = useState(true);

  const toColor = () => {
    // Get Context
    const image = document.getElementById("image");
    const c = document.getElementById("canvas");
    const ctx = c.getContext("2d");
    // Copy Image
    ctx.drawImage(image, 0, 0);
    // Get Image Data
    let imageBase = ctx.getImageData(0, 0, image.width, image.height);
    let data = imageBase.data;
    // Alter Each Pixel
    // New grayscale image = ( (0.3 * R) + (0.59 * G) + (0.11 * B) ).
    // https://www.tutorialspoint.com/dip/grayscale_to_rgb_conversion.htm
    let i = 0;
    for (i = 0; i < data.length; i += 4) {
      data[i + 0] = data[i + 0] * 0.3; // Red * 0.3
      data[i + 1] = data[i + 1] * 0.3; // Green * 0.59
      data[i + 2] = data[i + 2] * 0.3; // Blue * 0.11
    }
    // Display Result
    ctx.putImageData(imageBase, 0, 0);
  };

  const toBW = () => {
    // Get Context
    const image = document.getElementById("image");
    const c = document.getElementById("canvas");
    const ctx = c.getContext("2d");
    // Copy Image
    ctx.drawImage(image, 0, 0);
    // Get Image Data
    let imageBase = ctx.getImageData(0, 0, image.width, image.height);
    let data = imageBase.data;
    // Alter Each Pixel
    let i = 0;
    for (i = 0; i < data.length; i += 4) {
      var avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      data[i + 0] = avg;
      data[i + 1] = avg;
      data[i + 2] = avg;
    }
    // Display Result
    ctx.putImageData(imageBase, 0, 0);
  };

  const clearScreen = () => {
    // Get Context
    const c = document.getElementById("canvas");
    const ctx = c.getContext("2d");
    // Copy Image
    ctx.clearRect(0, 0, c.width, c.height);
  };

  return (
    <>
      <div id="menu">
        <p style={{ marginBottom: "-10px" }}>
          <button
            type="button"
            id="btn_copy"
            onClick={useGrey ? () => toColor() : () => toBW()}
            style={{ margin: "25px" }}
          >
            {useGrey ? "To Color" : "To Grey"}
          </button>
          <div
            style={{
              margin: "25px",
              marginRight: "50px",
              display: "inline-block",
            }}
          >
            <label for="grey">
              <input
                type="checkbox"
                id="chk_grey"
                name="grey"
                onClick={() => {
                  clearScreen();
                  setUseGrey(!useGrey);
                }}
                style={{ marginRight: "15px" }}
              />
              Use {useGrey ? " Color" : " Grey"}
            </label>
          </div>
        </p>
      </div>
      <div id="image_row">
        <img
          id="image"
          src={
            useGrey
              ? "https://skeltonnetworks.com/images/project_color/euphonium_grey.png"
              : "https://skeltonnetworks.com/images/project_color/euphonium.jpg"
          }
          width="240"
          height="320"
          style={{ border: "1px solid black", marginRight: "50px" }}
          alt="test_image"
        />
        <canvas
          id="canvas"
          width="240"
          height="320"
          style={{ border: "1px solid black" }}
        ></canvas>
      </div>
    </>
  );
}

export default function ColorThePast() {
  // Syntax Highlighter
  const renderers = {
    code: ({ language, value }) => {
      return (
        <SyntaxHighlighter
          style={materialDark}
          language={language}
          children={value}
          showLineNumbers
        />
      );
    },
  };

  // Body
  const body = `
  # Color The Past
  ### [GitHub Repository](https://github.com/zskelton/ColorThePast)
  ---

  ## Goal:

  Create color pictures from black and white. Since starting, I have found:

  - **Grey Scale to Color is HARD!**
  - **Color to Grey Scale is easy.**
    - *Method:* Average Red, Green, and Blue.
  ~~~js
  // Function to Convert to Greyscale
  const toGrey = () => {
    const image = document.getElementById("image");
    const c = document.getElementById("canvas");
    const ctx = c.getContext("2d");

    // Copy Image
    ctx.drawImage(image, 0, 0);

    // Get Image Data
    let imageBase = ctx.getImageData(0, 0, image.width, image.height);
    let data = imageBase.data;

    // Alter Each Pixel
    for (var i = 0; i < data.length; i += 4) {
      var avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      data[i + 0] = avg;
      data[i + 1] = avg;
      data[i + 2] = avg;
    }

    // Display Result
    ctx.putImageData(imageBase, 0, 0);
  };
  ~~~


  - Reference: [Tutorial Point RGB Conversion](https://www.tutorialspoint.com/dip/grayscale_to_rgb_conversion.htm)

  ## Status:
  Project is recently started, stay tuned for updates.

  ### ***Next Steps:***
  1. Explore Machine Learning
  2. Is it possible?
  3. Can it be automated?

  ## Progress:
  `;

  // Show
  return (
    <>
      <ReactMarkdown renderers={renderers} children={body} />
      <Demo />
    </>
  );
}
