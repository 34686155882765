import React from "react";
import ReactMarkdown from "react-markdown";

export default function Default() {
  const body = `
  # Projects:

  This page displays my coding projects. Interests range from simple applications to hardware and software integration. The menu to the left fills my projects, and links as necessary, to file repositories.
  `;

  return (
    <>
      <ReactMarkdown children={body} />
    </>
  );
}
