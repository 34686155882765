import React, { Component } from "react";
import { Link } from "react-router-dom";

// TODO:
// - Update Class by Location

class Menu extends Component {
  changeCurrent(from) {
    const home = document.getElementById("homeNav");
    const hist = document.getElementById("histNav");
    const proj = document.getElementById("projNav");

    if (from === "home") {
      home.className = "current";
      hist.className = "";
      proj.className = "";
      return;
    }

    if (from === "hist") {
      home.className = "";
      hist.className = "current";
      proj.className = "";
      return;
    }

    if (from === "proj") {
      home.className = "";
      hist.className = "";
      proj.className = "current";
      return;
    }
  }

  render() {
    var [, page] = window.location.pathname.split("/");
    this.changeCurrent(page);

    return (
      <nav id="nav-wrap" style={{ background: "#1c1c1c" }}>
        <ul id="nav" className="nav">
          <li className="current" id="homeNav">
            <Link to="/" onClick={() => this.changeCurrent("home")}>
              Home
            </Link>
          </li>
          <li id="histNav">
            <Link to="/History" onClick={() => this.changeCurrent("hist")}>
              History
            </Link>
          </li>
          <li id="projNav">
            <Link to="/Projects" onClick={() => this.changeCurrent("proj")}>
              Projects
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Menu;
