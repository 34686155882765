import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import Menu from "./components/Menu";
import Main from "./pages/Main";
import History from "./pages/History";
import Projects from "./pages/Projects";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const NoMatchPage = () => {
  return <h3>404 - Not found</h3>;
};

class App extends Component {
  render() {
    return (
      <DocumentTitle title="Z. Skelton Profile">
        <div className="App">
          <Router>
            <Menu />
            <Switch>
              <Route exact path="/" component={Main} />
              <Route exact path="/History" component={History} />
              <Route path="/History/:paper" component={History} />
              <Route exact path="/Projects" component={Projects} />
              <Route path="/Projects/:project" component={Projects} />
              <Route component={NoMatchPage} />
            </Switch>
          </Router>
        </div>
      </DocumentTitle>
    );
  }
}

export default App;
