/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import Default from "../files/projects/Default";
import Monitor from "../files/projects/Monitor";
import ColorThePast from "../files/projects/ColorThePast";
import { useParams } from "react-router";

export default function Projects() {
  const { project } = useParams();
  const [currentFile, setCurrentFile] = useState(<Default />);
  const [receivedData, setReceivedData] = useState(project);

  const setCurrent = (n) => {
    switch (n) {
      case "Monitor":
        setCurrentFile(<Monitor />);
        break;
      case "Color":
        setCurrentFile(<ColorThePast />);
        break;
      default:
        setCurrentFile(<Default />);
    }
  };

  if (receivedData) {
    setCurrent(receivedData);
    setReceivedData(null);
  }

  return (
    <section id="projects">
      <div className="row" style={{ marginTop: "50px" }}>
        <Container>
          <Row>
            <Col sm={3}>
              {/* Menu */}
              <nav style={{ alignItems: "left" }}>
                <span onClick={() => setCurrent("Default")}>
                  <h4>
                    <a>Projects:</a>
                  </h4>
                </span>
                <hr />
                <span onClick={() => setCurrent("Monitor")}>
                  <a>Monitor</a>
                </span>
                <br />
                <span onClick={() => setCurrent("Color")}>
                  <a>Color The Past</a>
                </span>
                <br />
              </nav>
            </Col>
            <Col sm={9}>
              {/* Articles */}
              <div
                id="content"
                style={{ padding: "20px", backgroundColor: "#EDEDED" }}
              >
                {currentFile}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
