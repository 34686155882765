import React from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

export default function Monitor() {
  // Syntax Highlighter
  const renderers = {
    code: ({ language, value }) => {
      return (
        <SyntaxHighlighter
          style={materialDark}
          language={language}
          children={value}
          showLineNumbers
        />
      );
    },
  };

  const body = `

  # Monitor Program

  ### [GitHub Repository](https://github.com/zskelton/Monitor)

  ##### v1.0 Status:
  ![Arudino](https://github.com/zskelton/Monitor/workflows/Arudino/badge.svg)
  ![.NET Framework Build](https://github.com/zskelton/Monitor/workflows/.NET%20Framework%20Build/badge.svg)
  ![CodeQL](https://github.com/zskelton/Monitor/workflows/CodeQL/badge.svg)
  ## Goal:

  Inspired by a [YouTube video](https://www.youtube.com/watch?v=RTdniu3gn3Y&feature=youtu.be{:target="_blank"}), my son asked me to build him a external monitor for his custom built desktop. This was the result:

  ![EnochMonitor1](https://skeltonnetworks.com/images/project_monitor/enoch_computer_2.jpg)

  The design utilizes two programs.
  1. Server - Windows WPF Application to Send Information.
  2. Client - Arduino Uno with Elegoo TFT-LCD Screen to display.

  ## Build:

  ### Windows Server
  ![WindowsMonitor](https://skeltonnetworks.com/images/project_monitor/monitor.png)
  Using Microsoft Blend, this application uses .NET C# to obtain information of the computer. To keep this simple, I pulled CPU, Memory, and Network information using .NETs [PerformanceCounter](https://docs.microsoft.com/en-us/dotnet/api/system.diagnostics.performancecounter?view=dotnet-plat-ext-5.0) and [NetworkInterface](https://docs.microsoft.com/en-us/dotnet/api/system.net.networkinformation.networkinterface?view=net-5.0) Libraries:

  ~~~csharp
  using System.Diagnostics;
  using System.Net.NetworkInformation;

  // Sudo Code - Refer to Repository for Working Version
  namespace MonitorServer
  {
    public partial class MainWindow : Window
    {
      // Create Variables
      private PerformanceCounter cpuCounter;
      private PerformanceCounter memCounter;
      private NetworkInterface netCounter;

      public MainWindow()
      {
        // Initialize Counters
        cpuCounter = new PerformanceCounter("Processor", "% Processor Time", "_Total");
        memCounter = new PerformanceCounter("Memory", "% Committed Bytes In Use", null);
        netCounter = getNetInterface(); // Custom Function to Pull Name of Interface
      }

      public OnTick()
      {
        // Get Value of CPU
        float cpuVal = cpuCounter.NextValue();
        lbl_cpuStatus.Content = String.Format("{0:0.00}%", cpuVal);
        // Get Value of Ram
        float memVal = memCounter.NextValue();
        lbl_memStatus.Content = String.Format("{0:0.00}%", memVal);
        // Get Network Values
        lbl_netReceived.Content = String.Format("Received - {0:0.0} MBs", netCounter.GetIPv4Statistics().BytesReceived/1024/1024);
        lbl_netSent.Content = String.Format("Sent - {0:0.0} MBs", netCounter.GetIPv4Statistics().BytesSent/1024/1024);
        lbl_netType.Content = netCounter.NetworkInterfaceType.ToString();
        // Send to Serial Port
        SendSerialPort(Data);
      }
    }
  }
  ~~~

  ### Arduino Client
  ![WindowsMonitor](https://skeltonnetworks.com/images/project_monitor/screen_data.jpg)
  Using Arduino Uno with an Elegoo TFT-LCD Screen, the data sends by serial port. For the layout, [Adafruit GFX Library](https://learn.adafruit.com/adafruit-gfx-graphics-library/overview?gclid=CjwKCAiA57D_BRAZEiwAZcfCxUymQmoZvFqkeLljiDIuOHngMK0kmmXpk5tDhezzdLvwa2bZx48nfBoCSDQQAvD_BwE) worked to create the basics. Two objects create the layout, a LABEL and a PROGRESS BAR.

  - Note: Each Object tracks when it changes. On the draw(), it only draws changes to save on memory and screen refreshes.

  ~~~cpp
  struct label {
      int x;
      int y;
      String text;
      bool changed;
  };

  struct bar {
      int x;
      int y;
      int h;
      int w;
      int value;
      bool changed;
  };

  label lblTitle; // Static
  bar barCpu; // Updates on change

  void setup(void) {
    // Start Serial
    Serial.begin(9600);
    // Init Objects
    lblTitle = { x: 0, y : 0, text : "Computer Monitor", changed : false };
    barCpu = { x: 0, y : 40, h : 10, w : 100, value : 0, changed : false };
  }

  void loop() {
      if (Serial.available() > 0) {
    // Get Data (If none, skip) - Fills Global
    getData();
    // Draw Label if changed
    if(lblTitle.changed == True) {
      drawText(lblTitle);
      lblTitle.changed = False;
    }
    // Draw CPU and sets to not changed after update.
    if(barCPU.changed == True) {
      drawBar(barCPU);
      barCPU.changed = False;
    }
      }
  }

  void eraseText(label obj, uint16_t color = BLACK, uint8_t size = 2) {
      int16_t  xb, yb;
      uint16_t wb, hb;
      screen.getTextBounds(obj.text, obj.x, obj.y, &xb, &yb, &wb, &hb);
      screen.fillRect(xb, yb, wb, hb, color);
  }

  void drawText(label obj, uint16_t color = WHITE, uint8_t size = 2) {
      eraseText(obj);
      screen.setRotation(3);
      screen.setCursor(obj.x, obj.y);
      screen.setTextColor(color);
      screen.setTextSize(size);
      screen.println(obj.text);
  }

  void drawBar(bar obj, uint16_t outline = WHITE, uint16_t color = GREEN) {
      screen.setRotation(3);
      screen.fillRect(obj.x, obj.y, obj.w, obj.h, BLACK);
      screen.drawRect(obj.x, obj.y, obj.w, obj.h, outline);
      screen.fillRect(obj.x, obj.y, obj.value, obj.h, color);
  }

  ~~~

  ### Connection
  To send data, the server sends a string ending with a newline ('\n') as a terminator. Each variable is separated by a colon (':').

  ~~~csharp
  String line = String.Format("{0}:{1}\\n", title, (int)cpuVal);
  ~~~

  ## Progress:
  TODOs:
  - [x] Create Working Version.
  - [ ] Get rid of UI, make as a service.
  - [ ] Remove Windows Dependence
  - [ ] Make stylized UI for Arduino Client
  - [ ] Create application to customize layout.
  `;

  return (
    <>
      <ReactMarkdown renderers={renderers} children={body} />
    </>
  );
}
