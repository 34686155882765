import React, { Component } from "react";
import Header from "../components/Header";
import About from "../components/About";
import Resume from "../components/Resume";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import resumeData from "../data/resumeData";

class Main extends Component {
  render() {
    return (
      <div>
        <Header resumeData={resumeData} />
        <About resumeData={resumeData} />
        <Resume resumeData={resumeData} />
        <ContactUs resumeData={resumeData} />
        <Footer resumeData={resumeData} />
      </div>
    );
  }
}

export default Main;
