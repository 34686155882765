/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
import MH510_HistLogistics from "../files/historypapers/MH510_Historiography_Logistics.pdf";
import MH520_KingAlfred from "../files/historypapers/MH520_King_Alfred.pdf";
import MH540_Kuwait from "../files/historypapers/MH540_Final_Kuwait.pdf";
import MH550_HistIndustrial from "../files/historypapers/MH550_Historiography_IndMob.pdf";
import Capstone from "../files/historypapers/FinalDraft - For Print.pdf";
import Default from "../files/historypapers/Default.pdf";
import { useParams } from "react-router";

import fileData from "../data/fileData";
import { Container, Row, Col } from "react-grid-system";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function History() {
  const { paper } = useParams();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentSelection, setCurrentSelection] = useState("Empty");
  const [currentFile, setCurrentFile] = useState(Default);
  const [receivedData, setReceivedData] = useState(paper);

  // Set Pages on Load
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  // React to Button Click
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  // Updates File
  const setCurrent = (n) => {
    switch (n) {
      case "MH510_Log":
        setCurrentSelection(n);
        setCurrentFile(MH510_HistLogistics);
        break;
      case "MH520_KingAlfred":
        setCurrentSelection(n);
        setCurrentFile(MH520_KingAlfred);
        break;
      case "MH540_Kuwait":
        setCurrentSelection(n);
        setCurrentFile(MH540_Kuwait);
        break;
      case "MH550_MilInd":
        setCurrentSelection(n);
        setCurrentFile(MH550_HistIndustrial);
        break;
      case "Capstone":
        setCurrentSelection(n);
        setCurrentFile(Capstone);
        break;
      default:
        setCurrentSelection("Empty");
        setCurrentFile(Default);
    }
  };

  // Set to Sent Page, if it Exists
  if (receivedData) {
    setCurrent(receivedData);
    setReceivedData(null);
  }

  return (
    <section id="history">
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <div className="row" style={{ marginTop: "50px" }}>
        <Container>
          <Row>
            <Col sm={3}>
              {/* Menu */}
              <nav style={{ alignItems: "left" }}>
                <span onClick={() => setCurrent("Capstone")}>
                  <a>Capstone</a>
                </span>
                <br />
                <span onClick={() => setCurrent("MH550_MilInd")}>
                  <a>Military Industrialism</a>
                </span>
                <br />
                <span onClick={() => setCurrent("MH540_Kuwait")}>
                  <a>Kuwait</a>
                </span>
                <br />
                <span onClick={() => setCurrent("MH520_KingAlfred")}>
                  <a>King Alfred's War</a>
                </span>
                <br />
                <span onClick={() => setCurrent("MH510_Log")}>
                  <a>Military Logistics</a>
                </span>
                <br />
              </nav>
            </Col>
            <Col sm={3}>
              {/* Information */}
              <h3 style={{ color: "#F06000" }}>
                <center>{fileData[currentSelection].fileName}</center>
              </h3>
              <hr />
              <div>
                <p>{fileData[currentSelection].fileDescription}</p>
              </div>
              <hr />
              <div>
                <center>
                  <span
                    onClick={() => {
                      pageNumber > 1 ? changePage(-1) : changePage(0);
                    }}
                    style={{ verticalAlign: "middle" }}
                    className="material-icons md-inactive"
                  >
                    arrow_left
                  </span>
                  <span style={{ padding: 20 }}>
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "1"}
                  </span>
                  <span
                    onClick={() => {
                      pageNumber < numPages ? changePage(1) : changePage(0);
                    }}
                    style={{ verticalAlign: "middle" }}
                    className="material-icons"
                  >
                    arrow_right
                  </span>
                </center>
              </div>
            </Col>
            <Col sm={4}>
              {/* Document */}
              <Document
                file={currentFile !== "Empty" ? currentFile : ""}
                onLoadSuccess={onDocumentLoadSuccess}
                loading="Loading File..."
                noData="No file selected."
              >
                <Page key={`page_${pageNumber}`} pageNumber={pageNumber} />
              </Document>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
