let fileData = {
  Empty: {
    name: "empty",
    shortName: "empty",
    fileName: "Pick a file:",
    fileDescription:
      "Files listed are writings contributed over a variety of historical topics.",
  },
  MH510_Log: {
    name: "MH510_Log",
    shortName: "Military Logistics",
    fileName: "Historiography - Military Logistics",
    fileDescription:
      "The first historiography review of my Masters in Military History of Norwich Univeristy. The topic was miltiary logistics.",
  },
  MH520_KingAlfred: {
    name: "MH520_KingAlfred",
    shortName: "King Alfred's War",
    fileName: "King Alfred's War",
    fileDescription:
      "A battle analysis of King Alfred's early battles before his exile.",
  },
  MH540_Kuwait: {
    name: "MH540_Kuwait",
    shortName: "Kuwaiti History",
    fileName: "Annotated Bibliography - Kuwait",
    fileDescription:
      "Annotated Bibliography of works surrounding the history of Kuwait and its relation to its bedouin citizens as the 'other.'.",
  },
  MH550_MilInd: {
    name: "MH550_MilInd",
    shortName: "Military Industrialism (Hist.)",
    fileName: "Historiography - Military Industrialism",
    fileDescription:
      "Indepth look at the historiography of works pertaining to military industrialism in the 1930s America.",
  },
  Capstone: {
    name: "Capstone",
    shortName: "Military Industrialism",
    fileName: "Military Industrialisim",
    fileDescription:
      "Capstone paper arguing the overlook by history scholars of the effect of training on the United States Army in its preparation for World War II. Often seen as underwhelming in its initial mobilization of industry, the American military exceeded all expectations by the end of the war. I argue this remarkable gain was the result of years of preparation between the wars as industrial mobilization went through the political debates of Washington D.C.",
  },
};

export default fileData;
