let resumeData = {
  imagebaseurl: "https://gitlab.bendimanor.com/zbert/portfolio.git",
  name: "Zack Skelton",
  role: "Tinkerer, Hobbyist, and Developer",
  linkedinId: "zach-skelton-09b60398",
  roleDescription:
    "Interested in multiple aspects of development, software, and hardware. Other experience in military logistics, history, and corrections.",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/zach-skelton-09b60398/",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "https://github.com/zskelton",
      className: "fa fa-github",
    },
  ],
  aboutme:
    "Working years in both corrections and miltiary logistics, my experience derives from building applications to improve the automation in these organizations. Further hobby development in microcontrollers and all aspects of computer science create for a full, rounded expereince.",
  address: "Iowa, United States",
  website: "https://www.skeltonnetworks.com/",
  education: [
    {
      UniversityName: "Norwich University",
      specialization: "Masters of Military History",
      MonthOfPassing: "Jun",
      YearOfPassing: "2020",
      Achievements: "GPA (4.0)",
    },
    {
      UniversityName: "Iowa State University",
      specialization: "Bachelors of History",
      MonthOfPassing: "Dec",
      YearOfPassing: "2006",
      Achievements: "Dean's List (GPA 3.5 and above)",
    },
  ],
  work: [
    {
      CompanyName: "U.S. Army Reserves",
      specialization: "Logistics Officer - Transporation",
      MonthOfLeaving: "",
      YearOfLeaving: "N/A",
      Achievements:
        "Deployments: Iraq (2003), Afghanistan (2013), Bulgaria (2017), Kuwait (2019)",
    },
    {
      CompanyName: "Story County Jail",
      specialization: "Some specialization",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2018",
      Achievements: "Some Achievements",
    },
  ],
  skillsDescription: "Coding Specific Skillsets",
  skills: [
    {
      skillname: "HTML5",
    },
    {
      skillname: "ReactJS",
    },
    {
      skillname: "CakePHP",
    },
    {
      skillname: "C# (WPF - .NET)",
    },
    {
      skillname: "C",
    },
    {
      skillname: "Assembly (x86 and 6502)",
    },
    {
      skillname: "Python",
    },
    {
      skillname: "Go",
    },
    {
      skillname: "Microcontroller Development/Electronic Circuits",
    },
  ],
};

export default resumeData;
